import React from 'react';
// Assets
import img from '../../../assets/img/accounting-about-us.jpg';

export default class AboutPage extends React.Component {
    render() {
        return (
            <div id="page">
                <section className="other_pages">
                    <article className="about-us">
                        <h1>About Us</h1>
                        <img src={img} alt="TerryMoyeCpa"/>
                        <p>We place an emphasis on quality of service. As a condition of our membership in the American
                            Institute of Certified Public Accountants, we participate in a rigorous quality control program,
                            which includes peer reviews of both our accounting and auditing practice. This review ensures our
                            compliance with the highest of professional standards and our commitment to maintaining the
                            highest continuing professional education standards as required by federal and state agencies.
                        </p>
                        <p>
                            What makes us unique is the personal attention, commitment and energy we bring to each client
                            relationship. Each client is assigned a team that includes a Partner; that team works together to
                            ensure that the client's planning and execution are designed specifically to meet her or his
                            accounting and financial needs and goals.
                        </p>
                    </article>
                </section>
            </div>
        );
    }
}