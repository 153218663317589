import React from 'react';

export default class ServicesPage extends React.Component {
    render() {
        return (
            <div>
                <div>
                    <article className="other_pages">
                        <h1>Services</h1>
                    </article>
                </div>
                <div className="service-list">
                    <aside className="list">
                        <h1 id="accounting">
                            <i className="fa fa-book fa-2x"/><span
                            className="service-header">Accounting</span>
                        </h1>
                        <p>We have a wide variety of accounting services that include, but are not limited to monthly,
                            quarterly or yearly financial statements, bookkeeping, financial record preparation, cash flow
                            projections and yearly audits.</p>
                        <h2>Our client base reflects the range of our expertise, and includes:</h2>
                        <div className='services-list'>
                            <ul>
                                <li>Individuals</li>
                                <li>Medical and Dental Practices</li>
                                <li>Retailers</li>
                                <li>Estates</li>
                                <li>Manufacturers</li>
                                <li>Restaurants</li>
                                <li>Commercial Organizations</li>
                                <li>Construction Companies</li>
                            </ul>
                        </div>
                        <div className="button-consultation">
                            <a
                                title="Schedule consultation"
                                href="/contact"
                                name="Schedule consultation"
                            >
                                Schedule your Appointment
                            </a>
                        </div>
                    </aside>
                </div>
                <div className="service-list servist-list-second">
                    <aside className="list">

                        <h1 id="taxes"><i className="fa fa-pencil-square-o fa-2x"/><span
                            className="service-header">Taxes</span></h1>
                        <p>We provide tax return preparation for all manner of our clients and their businesses. Specializing
                            in sophisticated and complex tax returns, personal and corporate, Non-profit and estate.</p>
                        <p>We guide our clients through a full range of tax planning and preparation decisions with strategies
                            that minimize your tax liabilities, maximize your cash flow and keep you on track to your
                            financial goals.</p>
                        <p>Our expertise, experience, analysis and thorough research allow us to optimize financial
                            opportunities to be found in existing as well as recently altered tax laws. We are knowledgeable
                            and up to date on the tax laws and can make sense of your receipts, bills and notices.</p>
                        <h2>Triple Check Income Tax Service</h2>
                        <div className='services-list'>
                            <ul>
                                <li>Tax Planing: Income, Sales, Payroll</li>
                                <li>Tax preparation and I.R.S. Representation</li>
                                <li>Audit Representation</li>
                                <li>Retirement Planing</li>
                            </ul>
                        </div>
                        <div className="button-consultation button-consultation2">
                            <a
                                title="Schedule consultation"
                                href="/contact"
                                name="Schedule consultation"
                            >
                                Schedule your Appointment
                            </a>
                        </div>
                    </aside>
                </div>
                <div className="service-list">
                    <aside className="list">
                        <h1 id="consulting">
                            <i className="fa fa-briefcase fa-2x"/><span
                            className="service-header">Consulting</span>
                        </h1>
                        <p>
                            We offer professional consultation regarding tax preparation, financial solvency, bankruptcy,
                            estate and wealth transfers as well as corporate consultations on a variety of issues depending on
                            the needs of the client.
                        </p>
                        <p>
                            We find ways for a companies to save money. When making complicated business decisions, we are
                            able
                            to calculate the effects of various decisions on a company, and will generally be able to relate
                            to management the financial benefits and consequences of a particular choice.
                        </p>
                        <div className="button-consultation">
                            <a
                                title="Schedule consultation"
                                href="/contact"
                                name="Schedule consultation"
                            >
                                Schedule your Appointment
                            </a>
                        </div>
                    </aside>
                </div>
            </div>
        );
    }
}