import React from 'react';

// Router
import { Link } from 'react-router-dom';

export default class Navigation extends React.PureComponent {
    render() {
        const { reRender } = this.props;
        return (
            <nav>
                <ul>
                    <li>
                        <Link to='/' onClick={reRender}>Home</Link>
                    </li>
                    <li>
                        <Link to='/services' onClick={reRender}>Services</Link>
                    </li>
                    {/*<li>*/}
                        {/*<a href="#" name="Service" aria-haspopup="true">Service</a>*/}
                        {/*<ul>*/}
                            {/*<li><Link to='/services#accounting' onClick={reRender} >Accounting</Link></li>*/}
                            {/*<li><Link to='/services#taxes' onClick={reRender} >Taxes</Link></li>*/}
                            {/*<li><Link to='/services#consulting' onClick={reRender} >Consulting</Link></li>*/}
                        {/*</ul>*/}
                    {/*</li>*/}
                    <li>
                        <Link to='/about' onClick={reRender}>About Us</Link>
                    </li>
                    <li>
                        <Link to='/contact' onClick={reRender}>Contact Us</Link>
                    </li>
                </ul>
            </nav>
        );
    }
}