import React from 'react';

// Router
import {
    BrowserRouter,
    Route,
    Switch,
} from 'react-router-dom';

// Layout Parts
import HomePageHeader from './ui/template/HomePageHeader';
import PageHeader from './ui/template/PageHeader';
import Footer from './ui/template/Footer';
import Navigation from './ui/template/Navigation';

// Pages
import HomePage from './ui/pages/Home';
import ContactPage from './ui/pages/Contact';
import AboutPage from './ui/pages/About';
import ServicesPage from './ui/pages/Services';

//Styles
import './assets/css/main.css';
import './assets/css/responsive.css';

class App extends React.Component {
    render() {
        return (
            <BrowserRouter>
                <div className="">
                    {window.location.pathname === '/' ? (
                        <HomePageHeader/>
                    ) : (
                        <PageHeader/>
                    )}
                    <div className='page'>
                        <Switch>
                            <Route exact path='/services' component={ServicesPage}/>
                            <Route exact path='/about' component={AboutPage}/>
                            <Route exact path='/contact' component={ContactPage}/>
                            <Route exact path='/' component={HomePage}/>
                        </Switch>
                    </div>
                    <Navigation {...{ reRender: this.reRender }}/>
                    <Footer/>
                </div>
            </BrowserRouter>
        );
    }

    reRender = () => {
        this.forceUpdate();
    };
}

export default App;
