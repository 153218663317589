import React from 'react';

import PromotionBannerDesktop from '../../../assets/img/promotion-banner-desktop.png';
import PromotionBannerMobile from '../../../assets/img/promotion-banner-mobile.png';

export default class HomePage extends React.Component {
    render() {
        return (
            <>
                <div className="promotion-banner">
                    <img className="promotion-banner__desktop" src={PromotionBannerDesktop} alt="" />
                    <img className="promotion-banner__mobile" src={PromotionBannerMobile} alt="" />
                </div>
                <div className="page">
                    <section className="servicelist">
                        <aside>
                            <div className="service">
                                <p className="icon"><i className="fa fa-book fa-4x"/></p>
                                <h3>Accounting and Review service</h3>
                                <p>Audits, Write-Up Services/Bookkeeping, Compilations, Reviews.</p>
                            </div>
                        </aside>
                        <aside>
                            <div className="service">
                                <p className="icon"><i className="fa fa-calendar-check-o fa-4x"/></p>
                                <h3> Taxation Planning</h3>
                                <p>Equity Analysis (S vs C, Etc), Acquisitions and Mergers, Sales And Liquidations, Transaction
                                    Analysis,
                                    Corporate, Partnership, Individual, Tax Strategies.</p>
                            </div>
                        </aside>
                        <aside>
                            <div className="service">
                                <p className="icon"><i className="fa fa-briefcase fa-4x"/></p>
                                <h3>Management Consulting</h3>
                                <p>Assistance with obtaining financing, Bankruptcy Accounting, Business Negotiations, Data
                                    Processing, Systems
                                    Analysis and review, Executive Search, Business Valuations.</p>
                            </div>
                        </aside>
                    </section>
                    <section className="servicelist">
                        <aside>
                            <div className="service">

                                <p className="icon"><i className="fa fa-money fa-4x"/></p>
                                <h3>Financial Planning</h3>
                                <p>Investment, Insurance, Retirement, Estate and Business Planning.</p>
                            </div>
                        </aside>
                        <aside>
                            <div className="service">
                                <p className="icon"><i className="fa fa-pencil-square-o fa-4x"/></p>
                                <h3>Compliance</h3>
                                <p>Corporate Tax Preparation, Individual Tax Preparation, Partnership Tax Preparation, Trust Tax
                                    Preparation,
                                    Employment Tax Preparation, Representation Taxing Authorities.</p>
                            </div>
                        </aside>
                        <aside>
                            <div className="service">

                                <p className="icon"><i className="fa fa-line-chart fa-4x"/></p>
                                <h3>Establishing</h3>
                                <p>Qualified Retirement Plan, Executive Compensation.</p>
                            </div>
                        </aside>
                    </section>
                    <section className="borderis"/>
                    <div className="full-page">
                        <div className="page">

                            <section className="startups">
                                <aside>
                                    <div className="startups-text">
                                        <h1><i className="fa fa-modx fa-3x icon2"/>Assistance to Start Up Companies</h1>
                                        <p>Establish Contacts with Banks Insurance, Companies and Attorneys, Initial Tax
                                            Registration and
                                            Filings, Strategic Planning Meetings, Set-Up of Accounting Systems.</p>
                                    </div>
                                </aside>
                            </section>
                        </div>
                    </div>
                    <div className="page">
                        <section className="tax">
                            <aside>
                                <div className="tax-text">
                                    <h1 id="tax-log">Terry Moye & Associates</h1>
                                    <h1>Trust your financial advisor</h1>
                                </div>
                            </aside>
                        </section>
                    </div>
                </div>
            </>
        );
    }
}
