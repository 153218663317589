import React from 'react';

export default class Footer extends React.PureComponent {
    render() {
        const year = new Date().getFullYear();
        return (
            <footer className='page-footer'>
                <h2>
                    ©{`${year}`} Terry Moye & Associates
                </h2>
            </footer>
        )
    }
}