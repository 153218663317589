import React from 'react';

export default class HomePageHeader extends React.PureComponent {
    render() {
        return (
            <header className="pages">
                <div className="logo">
                    <h1>Terry Moye & Associates</h1>
                    <h2>Certified Public Accountants</h2>
                </div>
            </header>
        )
    }
}