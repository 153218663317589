import React from 'react';

// Assets
import terry from '../../../assets/img/terry-moye.jpg';

export default class ContactPage extends React.Component {
    render() {
        return (
            <div id="page">
                <section>
                    <article className="other_pages">
                        <h1>Contact Us</h1>
                    </article>
                </section>
                <section>
                    <div className="contact">
                        <div className="contact-info">
                            <div className="contact1">
                                <h3>Terry Moye - Owner</h3>
                                <div className="terry-photo">
                                    <img
                                        src={terry}
                                        alt="Terry Moye Owner"
                                    />
                                </div>
                            </div>
                            <div className="contact2">
                                <h4>Address:</h4>
                                <p>800 Roosevelt Rd. Bldg. C #100 <br/> Glen Ellyn, Illinois 60137</p>
                                <p>Phone: 630-366-2080</p>
                                <p>Cell: 630-803-4444</p>
                                <p><br/>Office Hours: <br/> Monday-Friday 9:00 a.m. to 5:30 p.m</p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}