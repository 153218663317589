import React from 'react';

export default class HomePageHeader extends React.PureComponent {
    render() {
        return (
            <header id="first-page" className='main-page'>
                <div className="logo">
                    <h1>Terry Moye & Associates</h1>
                    <h2>Certified Public Accountants</h2>
                </div>
                <div className="advert">
                    <h1>All financial service &mdash; </h1>
                    <h2>Let us lead to your success..</h2>
                </div>
            </header>
        );
    }
}